import { createSlice } from "@reduxjs/toolkit";
import { produce } from 'immer';

// interface IInstanceData {
//     id: number;
//     instance_token: string;
//     questionnaire_id: number;
//     date_start: string;
//     date_modified: string;
//     last_section_id: number | null;
//     last_page_id: number | null;
//     status: string;
// };

// interface IApplicantData {
//     id: number;
//     name: string;
//     result_date: Date | null;
//     user_id: number | null;
//     isVerified: boolean | false;
//     email: string | null;
//     primary_questionnaire: IInstanceData;
//     visa_chances_questionnaire: IInstanceData;
// };

// interface ISessionData {
//     user_id: number | null;
//     display_tour: boolean;
//     email: string;
//     main: IApplicantData;
//     spouse: IApplicantData | null;
//     currentApplicant: number;
// };

// interface ISubscribedUser {
//     subscribedUser: string;
// };

const initialState = {
    sessionData: null,
    subscription: null,
    subscribedUser: null,
    paymentRedirectUrl: null,
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        resetSession: (state) => {
            return produce(state, draft => {
                draft.subscription = null;
                draft.subscribedUser = null;
                draft.sessionData = null;
                draft.paymentRedirectUrl = null;
            });
        },
        setSessionData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = action.payload;
            });
        },
        setCurrentApplicant: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    currentApplicant: action.payload
                };
            });
        },
        setApplicantData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey]: action.payload.applicantData
                };
            });
        },
        setResultDate: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey]: {
                        ...state.sessionData[action.payload.applicantKey],
                        result_date: action.payload.result_date
                    }
                };
            });
        },
        setQuestionnaireData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey]: {
                        ...state.sessionData[action.payload.applicantKey],
                        [action.payload.questionnaire]: {
                            ...state.sessionData[action.payload.applicantKey][action.payload.questionnaire],
                            date_modified: action.payload.date_modified,
                            last_section_id: action.payload.last_section_id,
                            last_page_id: action.payload.last_page_id,
                            status: action.payload.status
                        }
                    }
                };
            });
        },
        setSubscription: (state, action) => {
            return produce(state, draft => {
                draft.subscription = action.payload;
            });
        },
        setSubscribedUser: (state, action) => {
            return produce(state, draft => {
                draft.subscribedUser = action.payload;
            });
        },
        setPaymentRedirectUrl: (state, action) => {
            return produce(state, draft => {
                draft.paymentRedirectUrl = action.payload;
            });
        },
    }
});

export const {
    resetSession,
    setSessionData,
    setCurrentApplicant,
    setApplicantData,
    setResultDate,
    setQuestionnaireData,
    setSubscription,
    setSubscribedUser,
    setPaymentRedirectUrl,
} = globalSlice.actions;
export default globalSlice.reducer;

// Selectors
export const getSessionData = () => (state) => state.global.sessionData;
export const getUserSubscription = () => (state) => state.global.subscription;
export const getSubscribedUser = () => (state) => state.global.subscribedUser;
export const getIsSpouseSelected = () => (state) => state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id);
export const getCurrentApplicantKey = () => (state) => (state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id)) ? 'spouse' : 'main';
export const getCurrentApplicantData = () => (state) => state.global.sessionData?.[(state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id)) ? 'spouse' : 'main'] ?? null;
export const getPaymentRedirectUrl = () => (state) => state.global.paymentRedirectUrl;