import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { getSessionData, setApplicantData } from "../../store/slices/global";
import { closeModal, openModal } from "../../store/slices/modal";

import { cancelSpouseInvitation } from "../../services/applicants";

import cookiesHelper from "../../helpers/cookies";

const useCancelSpouseInvitation = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const sessionData = useSelector(getSessionData());

    const mutationFn = async () => {
        return await cancelSpouseInvitation();
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response) {
                queryClient.invalidateQueries({ queryKey: ['connections'] });
                queryClient.invalidateQueries({ queryKey: ['new-invitations'] });

                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__MAIN_QUEST__SPOUSE);

                dispatch(setApplicantData({
                    applicantKey: 'spouse',
                    applicantData: {
                        ...sessionData.spouse,
                        email: null,
                        isVerified: false,
                    },
                }));

                variables.onCloseModal();

                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        closeOnEsc: true,
                        closeOnOutsideClick: true,
                        icon: 'success',
                        content: 'Spouse invitation has been cancelled successfully. We have sent an email to the user to let them know. You can still fill out the questionnaire on their behalf.',
                        buttons: [
                            { label: 'Close', style: 'primary', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                        ],
                    },
                }));
            } else {
                variables.setServerError('Error occurred while cancelling spouse invitation.');
            };
        },
        onError: (error, variables) => {
            variables.setServerError(error?.message ?? 'Error occurred while cancelling spouse invitation.');
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessRunning(null);
        },
    });
};

export default useCancelSpouseInvitation;