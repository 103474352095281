import api from ".";

import cookiesHelper from "../helpers/cookies";

export const saveSpouse = async (payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post('applicants/spouse/save', payload);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};

export const resendSpouseInvitation = async (payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post('applicants/spouse/invitation/resend', payload);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};

export const cancelSpouseInvitation = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post('applicants/spouse/invitation/cancel');
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};

export const unlinkSpouse = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post('applicants/spouse/unlink');
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    };
};

export const getInstanceStatusForSubmit = async (payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const { applicantKey, questionnaireType } = payload;
        return await api.get(`applicants/instance/status/submit/${applicantKey}/${questionnaireType}`);
    } catch (error) {
        throw error;
    };
};

export const updateInstanceTarget = async (payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.patch(`applicants/instance/update-target/${payload.target}/${payload.requester}/${payload.applicantKey}`);
    } catch (error) {
        throw error;
    }
};

export const copyStatusInfoFromOrganization = async (organizationId) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`applicants/status-info/copy/organization/${organizationId}`);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const updateStatusInfoAtOrganizations = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`applicants/status-info/update/organizations/${applicantKey}`);
        return res.data;
    } catch (error) {
        throw error?.response?.data;
    }
};

export const getStatusInfo = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.get(`applicants/status-info/${applicantKey}`);
    } catch (error) {
        throw error;
    }
};

export const updateQuestionnaireStateInCRM = async (payload) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.get(`applicants/crm/questionnaire/${payload.applicantKey}/${payload.state}/${payload.isVisit ? 'visit' : 'inside'}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getApplicantDetail = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.get(`applicants/personal/${applicantKey}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const saveApplicantDetail = async (applicantKey, data) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.post(`applicants/personal/${applicantKey}/save`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateStatusManagerInfoInCRM = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.post(`applicants/update-crm/status-manager/${applicantKey}`);
        return response.data;
    } catch (error) {
        throw error;
    };
};

export const setStatusDate = async (applicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.patch(`applicants/set-status-date/${applicantKey}`);
        return !!response?.data?.data;
    } catch (error) {
        throw error;
    };
};

export const calculateStudyVisaChances = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`applicants/calculate/study-visa-chances`);
        return res.data ?? null;
    } catch (error) {
        throw error?.response?.data;
    };
};