import { useMutation } from "react-query";
import { useDispatch } from "react-redux";

import { signOut } from "../../services/auth";

import cookiesHelper from "../../helpers/cookies";
import { getTrackingPath } from "../../helpers/tracking";

import { resetSession } from "../../store/slices/global";

const useSignOut = () => {
    const dispatch = useDispatch();

    const mutationFn = async () => {
        return await signOut();
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => { },
        onError: (error, variables) => { },
        onSettled: (data, error, variables) => {
            if (
                window.location.pathname !== '/auth' &&
                !window.location.pathname.includes('/reset-password') &&
                !window.location.pathname.includes('/password-changed') &&
                !window.location.pathname.includes('/activate')
            ) {
                cookiesHelper.removeCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__MAIN_QUEST__MAIN);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__MAIN_QUEST__SPOUSE);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__VISA_CHANCES_QUEST__MAIN);
                dispatch(resetSession());
                window.location = getTrackingPath('/auth');
            };
        }
    });
};

export default useSignOut;