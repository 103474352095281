import api from ".";

import cookiesHelper from "../helpers/cookies";

export const signUp = async (payload) => {
    try {
        const res = await api.post(`users`, payload);
        return res?.data?.data ?? null;
    } catch (error) {
        throw error;
    }
};

export const sendActivationEmail = async (email) => {
    try {
        return await api.get(`users/send-activation-email/${email}`);
    } catch (error) {
        throw error;
    }
};

export const activateAccount = async (token) => {
    try {
        const res = await api.patch(`users/activate-account/${token}`);
        return res?.data?.data ?? null;
    } catch (error) {
        throw error;
    };
};

export const sendPasswordResetEmail = async (email) => {
    try {
        return await api.get(`users/send-password-reset-email/${email}`);
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (token, payload) => {
    try {
        return await api.patch(`users/reset-password/${token}`, payload);
    } catch (error) {
        throw error;
    }
};

export const signIn = async (payload) => {
    try {
        return await api.post(`users/sign-in`, payload);
    } catch (error) {
        throw error;
    }
};

export const refreshAuthToken = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.get(`users/refresh-token`);
    } catch (error) {
        throw error;
    }
};

export const signOut = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.post(`users/sign-out`);
    } catch (error) {
        throw error;
    }
};

export const disableDisplayTour = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.patch(`users/disable-tour`);
    } catch (error) {
        throw error;
    }
};

export const toggleTwoFactorAuthentication = async (status) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.patch(`users/two-factor-authentication/toggle/${status}`);
        return response.status;
    } catch (error) {
        throw error;
    }
};

export const optInOutNotificationEmails = async (payload) => {
    const { token, action } = payload;
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.patch(`users/notifications/${action}`);
    } catch (error) {
        throw error;
    }
};