import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import ReactDOMServer from 'react-dom/server';

import { getSessionData, setApplicantData } from "../../store/slices/global";
import { closeModal, openModal } from "../../store/slices/modal";

import { saveSpouse } from "../../services/applicants";

import { triggerEvent } from "../../helpers/tracking";
import cookiesHelper from "../../helpers/cookies";

const useSaveSpouse = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const sessionData = useSelector(getSessionData());

    const mutationFn = async (data) => {
        const { payload } = data;

        return await saveSpouse(payload);
    };

    return useMutation(mutationFn, {
        onMutate: (variables) => { },
        onSuccess: (response, variables) => {
            if (response?.data) {
                queryClient.invalidateQueries({ queryKey: ['connections'] });
                queryClient.invalidateQueries({ queryKey: ['new-invitations'] });

                triggerEvent({
                    name: 'add_spouse',
                    action: 'api_response',
                    category: 'Spouse',
                    email: sessionData.email,
                    user_id: sessionData.user_id,
                });

                const resData = response.data;

                if (resData?.primary_questionnaire?.instance_token) {
                    cookiesHelper.setCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__MAIN_QUEST__SPOUSE, resData.primary_questionnaire.instance_token, 24 * 60 * 60 * 1000);
                    delete resData.primary_questionnaire.instance_token;
                };

                dispatch(setApplicantData({
                    applicantKey: 'spouse',
                    applicantData: resData,
                }));

                if (variables.payload.type === 'save') {
                    if (variables.isExisting) {
                        variables.setServerSuccess('Spouse has been saved successfully.');
                    } else {
                        variables.onCloseModal();

                        dispatch(openModal({
                            name: 'SPOUSE_ADDED_MESSAGE_MODAL',
                            data: {
                                title: `${variables.payload.first_name} has been added!`,
                                spouseData: resData,
                                content: ReactDOMServer.renderToString(
                                    <>
                                        <p>Your spouse has been added to the system. You can input their information on their behalf.</p>
                                        <p className="mt-3">You can also invite your spouse to join Immproved.</p>
                                    </>
                                ),
                            },
                        }));
                    };
                } else {
                    variables.onCloseModal();

                    if (variables.isExisting) {
                        dispatch(openModal({
                            name: 'MESSAGE_MODAL',
                            data: {
                                closeOnEsc: true,
                                closeOnOutsideClick: true,
                                icon: 'success',
                                content: 'Spouse has been invited successfully. An email has been sent for your spouse to join Immproved. Please let your spouse know to check spam if an invitation is not received.',
                                buttons: [
                                    { label: 'Close', style: 'primary', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                                ],
                            },
                        }));
                    } else {
                        dispatch(openModal({
                            name: 'SPOUSE_ADDED_MESSAGE_MODAL',
                            data: {
                                title: `${variables.payload.first_name} has been added!`,
                                spouseData: resData,
                                content: ReactDOMServer.renderToString(
                                    <>
                                        <p>{`An email has been sent for ${variables.payload.first_name} to join Immproved. Please let ${variables.payload.first_name} know to check spam if an invitation is not received.`}</p>
                                        <p className="mt-3">You can also input their information on their behalf.</p>
                                    </>
                                ),
                            },
                        }));
                    };
                };
            } else {
                variables.setServerError('Error occurred while saving spouse.');
            };
        },
        onError: (error, variables) => {
            variables.setServerError(error?.message ?? 'Error occurred while saving spouse.');
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessRunning(null);
        },
    });
};

export default useSaveSpouse;